@tailwind base;
@tailwind components;
@tailwind utilities;

.gallery {
  .swiper-button-prev,
  .swiper-button-next {
    @apply h-8 w-8 rounded-full bg-blue-500;

    &:after {
      @apply text-sm text-white;
    }
  }

  .swiper-button-prev {
    &:after {
      @apply -ml-0.5;
    }
  }

  .swiper-button-next {
    &:after {
      @apply -mr-0.5;
    }
  }
}
